<template>
  <div class="dialog" v-show="showMask">
    <div class="dialog-container">
      <div class="dialog-title">
        <i class="iconfont" :class="type == 'danger' ? 'icon-tishi' : 'icon-wenhao'"></i>
        {{ title }}
      </div>
      <div class="content" v-html="content"></div>
      <div class="btns">
        <div v-if="type != 'confirm'" class="default-btn" @click="closeBtn">
          {{ cancelText }}
        </div>
        <div v-if="type == 'danger'" class="danger-btn" @click="dangerBtn">
          {{ dangerText }}
        </div>
        <div v-if="type == 'confirm'" class="confirm-btn" @click="confirmBtn">
          {{ confirmText }}
        </div>
      </div>
      <div class="close-btn" @click="closeMask"><i class="iconfont icon-close"></i></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    // 类型包括 defalut 默认， danger 危险， confirm 确认，
    type: {
      type: String,
      default: "default",
    },
    content: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    cancelText: {
      type: String,
      default: "取消",
    },
    dangerText: {
      type: String,
      default: "删除",
    },
    confirmText: {
      type: String,
      default: "确认",
    },
  },
  data() {
    return {
      showMask: false,
    };
  },
  methods: {
    closeMask() {
      this.showMask = false;
    },
    closeBtn() {
      this.$emit("cancel");
      this.closeMask();
    },
    dangerBtn() {
      this.$emit("danger");
      this.closeMask();
    },
    confirmBtn() {
      this.$emit("confirm");
      this.closeMask();
    },
  },
  mounted() {
    this.showMask = this.value;
  },
  watch: {
    value(newVal, oldVal) {
      this.showMask = newVal;
    },
    showMask(val) {
      this.$emit("input", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  .dialog-container {
    width: 490px;
    // width: 590px;
    height: 290px;
    // height: 361px;
    background: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    position: relative;
    .dialog-title {
      width: 100%;
      height: 60px;
      font-size: 18px;
      color: #696969;
      font-weight: 600;
      padding: 35px 25px 0 25px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .iconfont {
        font-size: 34px;
        color: #ffad1e;
        margin-right: 14px;
      }
    }
    .content {
      height: 196px;
      color: #797979;
      line-height: 24px;
      padding: 0 56px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .inp {
      margin: 10px 0 0 20px;
      width: 200px;
      height: 40px;
      padding-left: 4px;
      border-radius: 4px;
      border: none;
      background: #efefef;
      outline: none;
      &:focus {
        border: 1px solid #509ee3;
      }
    }
    .btns {
      width: 100%;
      height: 104px;
      line-height: 104px;
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: right;
      padding: 0 16px;
      box-sizing: border-box;
      & > div {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        color: #ffffff;
        background: #f1f1f1;
        border-radius: 8px;
        margin-right: 12px;
        cursor: pointer;
      }
      .default-btn {
        width: 64px;
        text-align: center;
        height: 37px;
        background-color: #b3b3b3;
        border-radius: 8px;
        color: #fff;
        transition: all 0.5s;
        &:hover {
          box-shadow: 2px 3px 10px #b3b3b3;
        }
      }
      .danger-btn {
        width: 64px;
        height: 37px;
        background-color: #6340c8;
        border-radius: 8px;
        color: #ffffff;
        text-align: center;
        transition: all 0.5s;
        &:hover {
          box-shadow: 2px 3px 10px #6340c8;
        }
      }
      .confirm-btn {
        color: #ffffff;
        background: #509ee3;
        &:hover {
          background: #6fb0eb;
        }
      }
    }
    .close-btn {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 18px;
      cursor: pointer;
      &:hover {
        font-weight: 600;
      }
    }
  }
}
</style>
